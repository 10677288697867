import React from 'react';
// import ReactDom from 'react-dom';
import Media from 'react-media';
import GreenDecorate from '../Components/GreenDecorate';
import alter from '../images/alternativa.png';
import './styles/About.scss';
import './styles/Trayectoria.scss';
class About extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      activar: false,
      description: props.data.about.description
    }
  }

  componentDidMount() {
    const details = document.getElementById('deatils-about')
    details.innerHTML = this.state.description
    if (visualViewport.width > 750) {
      this.props.differNav(this.state.activar, 'about')
    } else {
      this.props.differNav(false, 'about')
      this.setState({
        ...this.state.details,
        activar: false,
      })
    }

  }


  componentWillReceiveProps(nextProps) {
    this.setState({
      details: nextProps.data.about.description,
    })
    const details = document.getElementById('deatils-about')
    details.innerHTML = nextProps.data.about.description
  }

  render() {
    return (
      <>

        <div className="GreenDecorate" style={{ marginTop: "-1em" }}>
          <div className="About__decorate">
            <div></div>
            <h2> {this.props.data.nav.title} </h2>
            <p> {this.props.data.nav.details} </p>
          </div>
          <img src={alter} alt="" />
          <span>  </span>
        </div>
        <Media query="(max-width: 750px)"
          onChange={(e) => {
            if (this.state.activar === true && e) {
              console.log('me des-active', e)
              this.props.differNav(false, 'about')
              this.setState({
                ...this.state.details,
                activar: false,
              })
            } else if (this.state.activar === false && !e) {
              console.log('me active')
              this.props.differNav(true, 'about')
              this.setState({
                ...this.state.details,
                activar: true,
              })
            }
          }}
          render={() => (
            <GreenDecorate params={this.props.data} />
          )}
        />

        <div className="About">
          <section className="About-container">
            <div className="mobile-intro" style={{ marginBottom: 60 }}>
              <h2 style={{ fontSize: "22px", fontWeight: "bold" }} >{this.props.data.nav.title} </h2>
              <p style={{ fontSize: "18px", color: "#707070" }}>{this.props.data.nav.details} </p>
            </div>

            <div className="About__littleDescription">
              <div>
                <h2 > {this.props.data.about.titleLittle} </h2>
                <p>
                  {this.props.data.about.descriptionLittle}
                </p>
              </div>
            </div>
            <div className="About__details">
              <h2 className="alter"> {this.props.data.about.title} </h2>
              <p id="deatils-about">

              </p>
            </div>
          </section>
        </div>
      </>
    )
  }
}

export default About;