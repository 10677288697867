import React from 'react';
import { HashLink as Link } from 'react-router-hash-link'
import Media from 'react-media';

import Button from './Button';

import './styles/Hero.scss';
import triangleHero from '../images/triangleHero.png';
import handHello from '../images/handHello.png';
import eficienciaIcon from '../images/eficienciaIcon.PNG';
import arbitraje from '../images/arbitraje.PNG';
import calidadIcon from '../images/calidadIcon.PNG';
import handHelloLittle from '../images/handHelloLittle.png';
import calidadGreen from '../images/calidadGreen.png';
import confianzaGreen from '../images/confianzaGreen.png';
import eficienciaGreen from '../images/eficienciaGreen.png';
import calidadMochado from '../images/calidadSinMochar.png';
import confianzaMochado from '../images/confianzaSinMochar.png';
import eficienciaMochado from '../images/eficienciaSinMochar.png';



class Hero extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      language: props.state.language,
      title: props.state.hero.title,
      description: props.state.hero.description,
      viewMore: props.state.hero.viewMore,
      img: {
        heroImg1: calidadGreen,
        heroImg2: arbitraje,
        heroImg3: eficienciaIcon,
        heroImgTitle1: props.state.hero.calidadTitle,
        heroImgTitle2: props.state.hero.confianzaTitle,
        heroImgTitle3: props.state.hero.eficienciaTitle,
        textDetails: props.state.hero.calidad,
        imgDetails: calidadMochado,
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log('recibiendo actualizacion', nextProps)
    this.setState({
      ...this.state,
      language: nextProps.state.language,
      title: nextProps.state.hero.title,
      description: nextProps.state.hero.description,
      viewMore: nextProps.state.hero.viewMore,
      img: {
        ...this.state.img,
        heroImgTitle1: nextProps.state.hero.calidadTitle,
        heroImgTitle2: nextProps.state.hero.confianzaTitle,
        heroImgTitle3: nextProps.state.hero.eficienciaTitle,
        textDetails: nextProps.state.hero.calidad,
      }
    })
  }

  handleHero = (who) => {
    switch (who) {
      case '1':
        this.state.img.heroImg1 == calidadIcon ?
          this.setState({
            // ...this.state,
            img: {
              heroImg1: calidadGreen,
              heroImg2: arbitraje,
              heroImg3: eficienciaIcon,
              heroImgTitle1: this.props.state.hero.calidadTitle,
              heroImgTitle2: this.props.state.hero.confianzaTitle,
              heroImgTitle3: this.props.state.hero.eficienciaTitle,
              imgDetails: calidadMochado,
              textDetails: this.props.state.hero.calidad,
            }
          })
          :
          this.setState({
            // ...this.state,
            img: {
              ...this.state.img,
              heroImg1: calidadIcon,
              heroImgTitle1: this.props.state.hero.calidadTitle,
              heroImgTitle2: this.props.state.hero.confianzaTitle,
              heroImgTitle3: this.props.state.hero.eficienciaTitle,
              textDetails: null,
              imgDetails: null,
            }
          })
        break;
      case '2':
        this.state.img.heroImg2 == arbitraje ?
          this.setState({
            // ...this.state,
            img: {
              heroImg1: calidadIcon,
              heroImg2: confianzaGreen,
              heroImg3: eficienciaIcon,
              heroImgTitle1: this.props.state.hero.calidadTitle,
              heroImgTitle2: this.props.state.hero.confianzaTitle,
              heroImgTitle3: this.props.state.hero.eficienciaTitle,
              imgDetails: confianzaMochado,
              textDetails: this.props.state.hero.confianza,
            }
          })
          :
          this.setState({
            // ...this.state,
            img: {
              ...this.state.img,
              heroImg2: arbitraje,
              heroImgTitle1: this.props.state.hero.calidadTitle,
              heroImgTitle2: this.props.state.hero.confianzaTitle,
              heroImgTitle3: this.props.state.hero.eficienciaTitle,
              imgDetails: null,
              textDetails: null
            }
          })
        break;
      case '3':
        this.state.img.heroImg3 == eficienciaIcon ?
          this.setState({
            // ...this.state,
            img: {
              heroImg1: calidadIcon,
              heroImg2: arbitraje,
              heroImg3: eficienciaGreen,
              heroImgTitle1: this.props.state.hero.calidadTitle,
              heroImgTitle2: this.props.state.hero.confianzaTitle,
              heroImgTitle3: this.props.state.hero.eficienciaTitle,
              imgDetails: eficienciaMochado,
              textDetails: this.props.state.hero.eficiencia
            }
          })
          :
          this.setState({
            // ...this.state,
            img: {
              ...this.state.img,
              heroImg3: eficienciaIcon,
              heroImgTitle1: this.props.state.hero.calidadTitle,
              heroImgTitle2: this.props.state.hero.confianzaTitle,
              heroImgTitle3: this.props.state.hero.eficienciaTitle,
              imgDetails: null,
              textDetails: null,
            }
          })
        break;
    }
  }

  render() {

    return (
      <>
        <div className="Hero">
          <Media
            query="(max-width: 850px)"
            render={() => (
              <div className="Hero-decorate-little">
                <div className="Hero-decorate-little-left">
                  <p> {this.state.img.textDetails} </p>
                </div>
                <div className="Hero-decorate-little-right">
                  <img src={this.state.img.imgDetails} alt="" />
                </div>
              </div>
            )}
          />
          <Media query="(max-width: 850px)" render={() =>
            (
              <img className="Hero__img-responsive" src={handHelloLittle} alt="saludo de manos"></img>
            )}
          />
          <img className="Hero-triangle" src={triangleHero} alt="" />
          <div className="Hero-decorate">
            <div className="Hero-decorate-left">
              <p> {this.state.img.textDetails} </p>
            </div>
            <div className="Hero-decorate-right">
              <img src={this.state.img.imgDetails} alt="" />

            </div>
          </div>
          <div className="Hero__left">
            <img className="Hero-hand" src={handHello} alt="" />
            <h1 className="Hero__left-title">
              {this.state.title}
            </h1>
            <p className="Hero__left-description">
              {this.state.description}
            </p>
            <Link onClick={() => { window.scrollTo(0, 0); }} to="/acerca-de/#inicio" >
              <Button className="vermas" message={this.state.viewMore} />
            </Link>
          </div>
          <div className="Hero__right">
            <div>
              <img
                src={this.state.img.heroImg1}
                onClick={() => { this.handleHero('1') }}
                alt=""
                className="Hero__right-item"
              />

              <p className="Hero__rigth-title"> {this.state.img.heroImgTitle1} </p>

            </div>
            <div>
              <img
                src={this.state.img.heroImg2}
                onClick={() => { this.handleHero('2') }}
                alt=""
                className="Hero__right-item"
              />

              <p className="Hero__rigth-title"> {this.state.img.heroImgTitle2} </p>

            </div>
            <div>
              <img
                src={this.state.img.heroImg3}
                onClick={() => { this.handleHero('3') }}
                alt=""
                className="Hero__right-item"
              />

              <p className="Hero__rigth-title"> {this.state.img.heroImgTitle3} </p>

            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Hero;